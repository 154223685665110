<script setup lang="ts">
import window from '@@/bits/global'
import { nextTick, onMounted, onBeforeUnmount, ref } from 'vue'
import { uniqueId } from 'lodash-es'

const FOCUSABLE_ELEMENTS_QUERY = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
].join(', ')

function isElementContained(rootElement: HTMLElement, activeElement: Element | null): boolean {
  if (!activeElement) return false

  const activeElementRelativePosition = rootElement.compareDocumentPosition(activeElement)
  if (!activeElementRelativePosition) return false

  const isContained = (activeElementRelativePosition & Node.DOCUMENT_POSITION_CONTAINED_BY) > 0
  return isContained
}

const root = ref<HTMLDivElement>()
const lastFocusedElement = ref<Element | null>(window.document.activeElement)
const focusTrapId = uniqueId('focusTrap')

const afterFocusShiftsFlushed = (callback): void => {
  // Only check and ensure focus is contained after a 1ms `setTimeout`.
  // This is to allow `focus()` calls on child elements to take place first.
  // For that to happen, these calls should happen immediately or by a 0ms `setTimeout` at the latest.
  setTimeout(() => nextTick(callback), 1)
}

const getFocusTarget = (event: KeyboardEvent): HTMLElement | null => {
  const isTabPressed = event.key === 'Tab'
  const isShiftPressed = event.shiftKey

  // Prevent DOM searching if the user is not navigating with the keyboard.
  if (!isTabPressed) return null

  const rootElement = root.value as HTMLElement
  const activeElement = document.activeElement
  const isAlreadyTrapped = isElementContained(rootElement, activeElement)
  const focusableElements = rootElement.querySelectorAll(FOCUSABLE_ELEMENTS_QUERY)

  const firstFocusableElement = focusableElements[0] as HTMLElement
  const lastFocusableElement = focusableElements[focusableElements.length - 1] as HTMLElement

  if (isShiftPressed) {
    // Shift + Tab
    if (activeElement === firstFocusableElement || !isAlreadyTrapped) {
      event.preventDefault()
      return lastFocusableElement || rootElement
    }
  } else {
    // Tab
    if (activeElement === lastFocusableElement || !isAlreadyTrapped) {
      event.preventDefault()
      return firstFocusableElement || rootElement
    }
  }

  return null
}

const ensureFocusTrapped = (event: KeyboardEvent): void => {
  const isLatestDialog = allFocusTraps[0] === focusTrapId
  if (!isLatestDialog) return

  const focusTarget = getFocusTarget(event)
  if (focusTarget) focusTarget.focus()
}

onMounted((): void => {
  allFocusTraps.unshift(focusTrapId)

  // Focus dialog first.
  afterFocusShiftsFlushed(() => {
    const rootElement = root.value as HTMLElement
    const previousActiveElement = document.activeElement as HTMLElement | null

    if (rootElement && !isElementContained(rootElement, previousActiveElement)) {
      rootElement.focus()
    }
  })

  window.addEventListener('keydown', ensureFocusTrapped)
})

onBeforeUnmount((): void => {
  const currentFocusTrapIndex = allFocusTraps.indexOf(focusTrapId)

  // Remove current FocusTrap instance from array.
  allFocusTraps.splice(currentFocusTrapIndex, 1)

  /**
   *  Restore focus to latest focused element.
   */
  if (lastFocusedElement.value instanceof HTMLElement) {
    lastFocusedElement.value.focus()
  }

  window.removeEventListener('keydown', ensureFocusTrapped)
})
</script>

<script lang="ts">
/**
 * We use this array to track all instances of `FocusTrap`.
 * We need to define the array outside the `setup` script
 * since otherwise there will be one array per instance.
 */
const allFocusTraps: any[] = []

export default {}
</script>

<template>
  <!-- tabindex="-1" makes the element focusable via JS, but not focusable by the user -->
  <div ref="root" tabindex="-1">
    <!-- @slot Content to be focus-trapped. -->
    <slot></slot>
  </div>
</template>
